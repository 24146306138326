import React, { useEffect, useState, useContext } from "react";
import app from "../firebase";

export const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [profile, setProfile] = useState(null)
 
  const register = (email, password) => {
    return (
      app.auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential)=>{
        var user = userCredential.user
        user.sendEmailVerification()
      })
    )
  }

  const login = (email, password) => {
    return (
      app.auth()
      .signInWithEmailAndPassword(email, password)
    )
  }

  const logout = () => {
    return (
      app.auth().signOut()
    )
  }

  const reset = (email) => {
    return (
      app.auth()
      .sendPasswordResetEmail(email)
    )
  }

  const updateEmail = (email) => {
    return user.updateEmail(email)
  }

  const updatePassword = (password) => {
    return user.updatePassword(password)
  }

  async function reloadUser() {
    await user.reload()
    const unsubscribe = app.auth().onAuthStateChanged(currentUser => {
      setUser(currentUser)
      loadProfile()
    })
    return unsubscribe
  }

  const loadProfile = (currentUser) => {
    currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
      const data = { 
        token: idToken
      };
      fetch(`${process.env.GATSBY_API_URL}/skllchain/profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(data => {
        if (data.profile) {
          setProfile(data.profile)
        } else {
          setProfile(profile)
        }
      })
      .catch(() => {
        setProfile(profile)
      });
    }).catch(() => {
      setProfile(profile)
    });
  }

  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged(currentUser => {
      setUser(currentUser)
      loadProfile(currentUser)
    })
    return unsubscribe
  }, []);

  const value = {
    user,
    profile,
    loadProfile,
    reloadUser,
    register,
    login,
    logout,
    reset,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider
      value={value}
    >
      {children}
    </AuthContext.Provider>
  );
};
