module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SKLL Chain | Software Engineering and Project Management Solutions","short_name":"SKLL Chain","start_url":"/","background_color":"#111","theme_color":"#111","display":"standalone","description":"SKLL Chain is a software production company which provides software engineering and consulting services. Specializes on project management platforms and optimized business solutions.","icon":"src/assets/images/skulls/skull-06.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"69400131235580d4b45179ca5dac0de0"},
    }]
