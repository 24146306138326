import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    '&::selection': {
      backgroundColor: '#546e7a'
    },
    fontFamily: [
      '"IBM Plex Mono"',
    ].join(','),
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#18ffff'
    },
    secondary: {
      main: '#546e7a'
    },
    background: {
      default: '#121212',
    },
  },
});

export default theme;
