import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LinkIn } from '../../components/Link'

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import EmojiEventsIcon from '@material-ui/icons/EmojiEventsOutlined';
import FingerprintIcon from '@material-ui/icons/FingerprintOutlined';
import BuildIcon from '@material-ui/icons/BuildOutlined';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import SendIcon from '@material-ui/icons/SendOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuIcon from '@material-ui/icons/Menu';

import Container from '@material-ui/core/Container';

import dotsBg from '../../assets/images/dots.png'
import carbonBg from '../../assets/images/carbon.png'

import { useAuth } from "../../context"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundImage: `url(${dotsBg})`,
    backgroundSize: 'contain'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    margin: theme.spacing(1),
    color: '#fff',
    display: 'flex',
    marginLeft:'auto',
    marginRight:'20px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderWidth: '1px',
    background: `url(${carbonBg}) repeat left top`,
    [theme.breakpoints.up('md')]: {
      borderWidth: '1px 0px 1px 1px',
      background: `transparent`,
    },
  },
  content: {
    flexGrow: 1,
  },
}));

function ResponsiveDrawer(props) {
  const { children } = props
  const { user, profile } = useAuth()

  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        <ListItem button component={LinkIn} to='/works'
          onClick={() => mobileOpen && handleDrawerToggle()}
        >
          <ListItemIcon><EmojiEventsIcon /></ListItemIcon>
          <ListItemText primary='works' />
        </ListItem>
        <ListItem button component={LinkIn} to='/production'
          onClick={() => mobileOpen && handleDrawerToggle()}
        >
          <ListItemIcon><BuildIcon /></ListItemIcon>
          <ListItemText primary='production' />
        </ListItem>
        <ListItem button component={LinkIn} to='/services'
           onClick={() => mobileOpen && handleDrawerToggle()}
        >
          <ListItemIcon><BubbleChartIcon /></ListItemIcon>
          <ListItemText primary='services' />
        </ListItem>
        <ListItem button component={LinkIn} to='/contact'
           onClick={() => mobileOpen && handleDrawerToggle()}
        >
          <ListItemIcon><SendIcon /></ListItemIcon>
          <ListItemText primary='contact' />
        </ListItem>
        <Divider variant="middle"/>
        <ListItem button component={LinkIn} to='/console'
           onClick={() => mobileOpen && handleDrawerToggle()}
        >
          <ListItemIcon><FingerprintIcon color='primary' /></ListItemIcon>
          <ListItemText primary='console'/>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem button component={LinkIn} to='/invoicing'
          onClick={() => mobileOpen && handleDrawerToggle()}
        >
          <ListItemIcon><AssignmentIcon /></ListItemIcon>
          <ListItemText primary='invoicing' />
        </ListItem>
        { (user && user.emailVerified
            && profile
            && profile.is_setup
            && profile.is_active
            && !profile.is_blocked
        ) ? (<>

        </>
        ) : null }
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Container maxWidth={false}
        disableGutters
        >
          <div style={{
            minHeight: `100vh`,
            borderStyle: 'solid',
            borderColor: '#fff',
            borderWidth: '1px 1px 1px 1px',
          }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
            {children}
          </div>
        </Container>
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
